.toast-responsive {
    margin-left: auto;
    margin-right: 0;
}
  
@media (max-width: 600px) {
    .toast-responsive {
      width: 50%;
    }
}
  
@media (min-width: 601px) and (max-width: 960px) {
    .toast-responsive {
      width: 70%;
    }
}  