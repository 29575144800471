  /* Style your items */
  .media {
    height: 100px;
  }

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    cursor: pointer;
    font-family: Plus Jakarta Text, sans-serif;
  }

  .subcontent {
    display: flex;
    margin-bottom: 15px;
    align-content: space-between;
    flex-flow: column wrap;
    height: 10px;
  }

  .chip {
    margin-bottom: 20px;
  }

  .content {
    height: 100px;
  }

  .noData {
    margin-top: 10% !important;
    text-align: center;
  }