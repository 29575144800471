.left-icon {
    height: 18px;
    width: 18px;
    background-size: 18px;
    position: absolute;
    background-repeat: no-repeat;
    cursor: pointer;
    left: 0.8em;
    top: 0.9em;
    z-index: 9;
}

.profile-icon {
    background-image: url("../assets/profile.png");
}

.email-icon {
    background-image: url("../assets/email.png");
}

.password-icon {
    background-image: url("../assets/lock.png");
}

.right-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.hide {
    background-image: url("../assets/ic_password_invisible.png");
}

.show {
    background-image: url("../assets/ic_password_visible.png");
}

.tabButton {
    border: none;
    padding: 15px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 255);
    color: #666867;
    cursor: pointer;
    width: 100px;
}

.active,
.tabButton:hover {
    color: black;
    font-weight: 600;
}

.toast-responsive {
    margin-left: auto;
    margin-right: 0;
}
  
@media (max-width: 600px) {
    .toast-responsive {
      width: 50%;
    }
}
  
@media (min-width: 601px) and (max-width: 960px) {
    .toast-responsive {
      width: 70%;
    }
}  