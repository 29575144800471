.left-icon {
    height: 18px;
    width: 18px;
    background-size: 18px;
    position: absolute;
    background-repeat: no-repeat;
    cursor: pointer;
    left: 0.8em;
    top: 0.9em;
    z-index: 9;
}

.email-icon {
    background-image: url("../assets/email.png");
}

.right-icon {
    position: absolute;
    cursor: pointer;
    right: 0.5em;
    top: 0.9em;
    z-index: 9;
}

.hide {
    background-image: url("../assets/ic_password_invisible.png");
}

.show {
    background-image: url("../assets/ic_password_visible.png");
}

.password-icon {
    background-image: url("../assets/lock.png");
}

.toast-responsive {
    margin-left: auto;
    margin-right: 0;
}
  
@media (max-width: 600px) {
    .toast-responsive {
      width: 50%;
    }
}
  
@media (min-width: 601px) and (max-width: 960px) {
    .toast-responsive {
      width: 70%;
    }
}  